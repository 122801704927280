import type { App, Route } from "@pimo/pimo-app-builder";
import {
  CustomFormFields,
  PimoFormDialog,
  PimoFormEvent,
} from "@pimo/pimo-components";
import { CommentsByYearAndQuarter } from "in-types";
import { CSSProperties } from "react";
import { generatePath } from "react-router-dom";
import { InAppState } from "../app/app";
import { APP_ROUTES } from "../app/constants";
import {
  fetchOE,
  fetchOEComments,
  fetchOEInitiatives,
  saveCommentsForEO,
} from "../app/helpers/fetch-helpers";
import {
  OE_COMMENTS_TABLE_TYPE,
  OECommentsTable,
} from "../components/form-fields/oe-comments-table/oe-comments-table";
import { INFormConfirmationDialog } from "../components/in-form-confirmation-dialog";
import { mapCommentsToArray } from "in-utils";

const COMMENTS_CUSTOM_FIELDS = {
  [OE_COMMENTS_TABLE_TYPE]: OECommentsTable,
} as const satisfies CustomFormFields<typeof OE_COMMENTS_TABLE_TYPE>;

export function buildCommentsFormRoute(
  app: App<InAppState>,
  parentRoute: Route<"oeId" | "viewname", InAppState>
) {
  const dialog = app.createView<"oeId" | "viewname" | "initiativeId">({
    name: "Edit-Initiatives",
  });
  const pimoForm = new PimoFormDialog<
    {
      comments: CommentsByYearAndQuarter;
      dialogStyles?: CSSProperties;
    },
    typeof COMMENTS_CUSTOM_FIELDS
  >();

  // Register Custom Table Definition
  pimoForm.registerFormField(OE_COMMENTS_TABLE_TYPE, OECommentsTable);

  const tab = pimoForm.addFormTab({ label: "Comments" });

  tab.addFormField({
    name: "comments",
    type: OE_COMMENTS_TABLE_TYPE,
  });

  const form = dialog.addComponent({ component: pimoForm });

  form.mapVisibility((state) => !!state.currentOE);
  form.mapState((state) => {
    const currentOEId = String(state.currentOE?.id ?? "");
    const commentsNavEntry = {
      label: "Comments",
      className: "comments-nav-entry",
      path: generatePath(APP_ROUTES.oeComments, {
        oeId: currentOEId,
        viewname: "initiatives",
      }),
    };

    pimoForm.setTitle(state.currentOE?.operatingEntity ?? "");
    return {
      actions: {
        "save-and-back": { visible: false },
      },
      activeNavigationEntry: commentsNavEntry,
      comments: state.currentOEComments ?? [],
      dialogStyles: {
        ".comments-nav-entry > .MuiBox-root:nth-of-type(2)": {
          display: "none",
        },
      },
      navigationEntries: [commentsNavEntry],
      runValidationOn: "blur",
    };
  });

  createConfirmationDialog();

  form.on("submit", ({ payload }) => {
    const appState = app.getAppState();
    const currentOE = appState.currentOE;
    const oeId = currentOE?.id;

    if (!oeId || !payload?.comments) {
      return;
    }

    void saveCommentsForEO(Number(oeId), payload.comments);

    /**
     * @todo: remove direct manipulation of state
     */
    currentOE.comments = mapCommentsToArray(payload.comments);

    app.patchAppState({
      currentOE,
      currentOEComments: payload.comments,
    });
  });

  form.on("cancel-with-changes", () => {
    app.patchAppState({
      showEditFormConfirmationDialog: true,
    });
  });

  (["cancel", "close"] satisfies PimoFormEvent[]).forEach((event) => {
    form.on(event, closeDialog);
  });

  const route = parentRoute.createChildRoute({
    path: APP_ROUTES.oeComments,
    view: dialog,
    isOverlayChild: true,
  });

  route.on("load", async ({ payload }) => {
    const { oeId } = payload?.parameters || {};

    if (!oeId) {
      return;
    }

    const [currentOE, currentOEInitiatives, currentOEComments] =
      await Promise.all([
        fetchOE(oeId),
        fetchOEInitiatives(oeId),
        fetchOEComments(Number(oeId)),
      ]);

    app.patchAppState({
      currentOE,
      currentOEComments,
      currentOEInitiatives,
      editFormConfirmationDialogType: "cancel-editing",
    });
  });

  function closeDialog() {
    const currentOE = app.getAppState().currentOE;

    if (!currentOE) {
      app.navigate(APP_ROUTES.home);
    }

    app.navigate(
      generatePath(APP_ROUTES.operatingEntities, {
        oeId: String(currentOE?.id),
        viewname: "initiatives",
      })
    );
  }

  function createConfirmationDialog() {
    const confirmationDialog = dialog.addComponent({
      component: INFormConfirmationDialog,
    });

    confirmationDialog.mapVisibility(
      ({ showEditFormConfirmationDialog }) =>
        showEditFormConfirmationDialog && showEditFormConfirmationDialog != null
    );

    confirmationDialog.mapState(({ editFormConfirmationDialogType }) => ({
      type: editFormConfirmationDialogType,
    }));

    function closeConfirmationDialog() {
      app.patchAppState({
        showEditFormConfirmationDialog: false,
      });
    }

    confirmationDialog.on("close", () => {
      closeConfirmationDialog();
      closeDialog();
    });

    confirmationDialog.on("continue", () => {
      closeConfirmationDialog();
    });
  }
}
