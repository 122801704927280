import type { App, Route } from "@pimo/pimo-app-builder";
import {
  type AdditionalFormProps,
  type CustomFormFields,
  type FormNavigationDirection,
  PimoFormDialog,
} from "@pimo/pimo-components";
import type { FormTab } from "@pimo/pimo-components/src/lib/pimo-components/pimo-form/form-tab";
import type { FormNavigationEntry } from "@pimo/pimo-components/src/lib/pimo-components/pimo-form/navigation/navigation-wrapper";
import dayjs from "dayjs";
import quarterofyear from "dayjs/plugin/quarterOfYear";
import {
  type EditInitiativesResponse,
  FUNCTION_TYPES,
  IMPACT_TYPES,
  IMPLEMENTATION_ARM_TYPES,
  INITIATIVE_PHASES,
  OperatingEntity,
  QUALITATIVE_IMPACT_TYPES,
  QUANTITATIVE_IMPACT_TYPES,
} from "in-types";
import { sortInitiativesByUid } from "in-utils";
import type { FieldValues } from "react-hook-form";
import { generatePath } from "react-router-dom";

import { ErrorOutline } from "@mui/icons-material";
import { styled } from "@mui/material";
import { InAppState } from "../app/app";
import { APP_ROUTES } from "../app/constants";
import {
  addNewInitiative,
  fetchOE,
  fetchOEInitiatives,
  fetchOEs,
  markInitiativeDeleted,
  saveInitiative,
} from "../app/helpers/fetch-helpers";
import {
  convertEditInitiativeToInitiative,
  convertInitiativeToEditInitiative,
  getEmptyInitiative,
} from "../app/utils/convert-edit-initiative";
import { AddInitiativeButton } from "../components/add-initiative-button/add-initiative-button";
import { DraftIcon } from "../components/draft-icon/draft-icon";
import { INFormFieldInfoSlot } from "../components/form-fields/in-form-field-info-slot";
import {
  INITIATIVE_ENABLER_SUB_FIELD_TYPE,
  InitiativeEnablerSubField,
} from "../components/form-fields/initiative-enablers-fields/initiative-enablers-fields";
import {
  INITIATIVE_IMPACT_TABLE_TYPE,
  InitiativeImpactTable,
} from "../components/form-fields/initiative-impact-table/initiative-impact-table";
import {
  INITIATIVE_LINE_OF_BUSINESS_FIELD,
  InitiativeLineOfBusinessField,
} from "../components/form-fields/initiative-line-of-business-fields/initiative-line-of-business-field";
import {
  INITIATIVE_LINE_OF_BUSINESS_OTHER_FIELD,
  InitiativeLineOfBusinessOtherField,
} from "../components/form-fields/initiative-line-of-business-fields/initiative-line-of-business-other-field";
import { INFormConfirmationDialog } from "../components/in-form-confirmation-dialog";
import { UpdateIcon } from "../components/update-icon/update-icon";
import { FORM_FIELD_INFO_TEXT } from "./form-field-info-text";
import {
  type FormFieldErrors,
  REQUIRED_ERROR,
  validateFormFields,
} from "./validate-form-fields";

dayjs.extend(quarterofyear);

const CUSTOM_FIELDS = {
  [INITIATIVE_IMPACT_TABLE_TYPE]: InitiativeImpactTable,
  [INITIATIVE_ENABLER_SUB_FIELD_TYPE]: InitiativeEnablerSubField,
  [INITIATIVE_LINE_OF_BUSINESS_FIELD]: InitiativeLineOfBusinessField,
  [INITIATIVE_LINE_OF_BUSINESS_OTHER_FIELD]: InitiativeLineOfBusinessOtherField,
} as const satisfies
  | CustomFormFields<typeof INITIATIVE_IMPACT_TABLE_TYPE>
  | CustomFormFields<typeof INITIATIVE_ENABLER_SUB_FIELD_TYPE>
  | CustomFormFields<typeof INITIATIVE_LINE_OF_BUSINESS_FIELD>
  | CustomFormFields<typeof INITIATIVE_LINE_OF_BUSINESS_OTHER_FIELD>;

// eslint-disable-next-line react-refresh/only-export-components
const ValidationIndicator = styled(ErrorOutline)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export function buildFormRoute(
  app: App<InAppState>,
  parentRoute: Route<"oeId" | "viewname", InAppState>
) {
  const dialog = app.createView<"oeId" | "viewname" | "initiativeId">({
    name: "Edit-Initiatives",
  });

  const pimoForm = new PimoFormDialog<
    EditInitiativesResponse,
    typeof CUSTOM_FIELDS
  >();

  // Register Custom Field Definitions
  pimoForm.registerFormField(
    INITIATIVE_IMPACT_TABLE_TYPE,
    InitiativeImpactTable
  );
  pimoForm.registerFormField(
    INITIATIVE_ENABLER_SUB_FIELD_TYPE,
    InitiativeEnablerSubField
  );
  pimoForm.registerFormField(
    INITIATIVE_LINE_OF_BUSINESS_FIELD,
    InitiativeLineOfBusinessField
  );
  pimoForm.registerFormField(
    INITIATIVE_LINE_OF_BUSINESS_OTHER_FIELD,
    InitiativeLineOfBusinessOtherField
  );

  const initiativeTab = pimoForm.addFormTab({
    label: "Initiative",
    validationIndicatorSlot: <ValidationIndicator />,
  });
  const impactTab = pimoForm.addFormTab({
    label: "Impact",
    validationIndicatorSlot: <ValidationIndicator />,
  });
  const enablerTab = pimoForm.addFormTab({
    label: "Enabler",
    validationIndicatorSlot: <ValidationIndicator />,
  });

  buildInitiativeTab(initiativeTab);
  buildImpactTab(impactTab);
  buildEnablerTab(enablerTab);

  const form = dialog.addComponent({ component: pimoForm });

  // Only show form if the currentOE is loaded to avoid flickering
  form.mapVisibility((state) => {
    return !!state.currentOE;
  });

  form.mapState((state) => {
    const initiatives = sortInitiativesByUid(
      state.currentOEInitiatives.initiatives
    );
    const initiative = state.currentEditInitiative;
    const isDraft = Boolean(initiative?.draft);
    const isNewInitiative = Boolean(state.currentOE && !initiative?.uid);
    const activeNavigationEntry = isNewInitiative
      ? {
          label: "New Initiative",
          path: generatePath(APP_ROUTES.addInitiative, {
            oeId: String(state.currentOE?.id ?? ""),
            viewname: "initiatives",
          }),
        }
      : {
          label: initiative?.initiativeName ?? "",
          path: generatePath(APP_ROUTES.editInitiatives, {
            oeId: String(state.currentOE?.id ?? ""),
            viewname: "initiatives",
            initiativeId: String(initiative?.uid ?? ""),
          }),
        };
    const newInitiativeNavEntry = {
      label: "New Initiative",
      path: generatePath(APP_ROUTES.addInitiative, {
        oeId: String(state.currentOE?.id ?? ""),
        viewname: "initiatives",
      }),
    } satisfies FormNavigationEntry;
    const navigationEntries =
      initiatives?.map(
        (initiative) =>
          ({
            label: initiative.initiativeName,
            path: generatePath(APP_ROUTES.editInitiatives, {
              oeId: String(state.currentOE?.id ?? ""),
              viewname: "initiatives",
              initiativeId: String(initiative?.uid ?? ""),
            }),
            slot: initiative.draft ? (
              <DraftIcon />
            ) : (
              <UpdateIcon status={initiative.updateStatus} />
            ),
          }) as FormNavigationEntry
      ) ?? ([] as FormNavigationEntry[]);

    if (isNewInitiative) {
      navigationEntries?.splice(0, 0, newInitiativeNavEntry);
    }

    pimoForm.setTitle(state.currentOE?.operatingEntity ?? "");

    return {
      ...initiative,
      actions: {
        save: {
          label: isDraft ? "Publish" : "Save as Draft",
          onClick: onDraftSave,
          visible: isDraft || initiative?.draft == null,
        },
        "save-and-next": { label: isNewInitiative ? "Next" : "Save & Next" },
        "save-and-close": {
          overrideValidationTarget: isDraft ? undefined : "all-tabs",
        },
        delete: {
          label: "Delete",
          visible: true,
        },
      },
      activeNavigationEntry,
      navigationEntries,
      navigationSlot: (
        <AddInitiativeButton
          label="+ Add Initiative"
          route={generatePath(APP_ROUTES.addInitiative, {
            oeId: String(state.currentOE?.id ?? ""),
            viewname: "initiatives",
          })}
        />
      ),
      saveOnAction: !isNewInitiative,
      validationTarget: isDraft
        ? "none"
        : isNewInitiative
          ? "current-tab"
          : "all-tabs",
      unlockImpactValues:
        isDraft ||
        isNewInitiative ||
        initiative?.initiativePhase === "Idea / Backlog",
      validateFormData: validateFormFields,
      runValidationOn: "blur",
      useWatchForEntireForm: true,
    } satisfies EditInitiativesResponse &
      Omit<AdditionalFormProps, "validateFormData"> & {
        unlockImpactValues?: boolean;
        validateFormData?: (
          formData: EditInitiativesResponse
        ) => FormFieldErrors;
        useWatchForEntireForm: boolean;
      };
  });

  registerFormEvents();
  createConfirmationDialog();

  const route = parentRoute.createChildRoute({
    path: APP_ROUTES.editInitiatives,
    view: dialog,
    isOverlayChild: true,
  });
  const createRoute = parentRoute.createChildRoute({
    path: APP_ROUTES.addInitiative,
    view: dialog,
    isOverlayChild: true,
  });

  createRoute?.on("load", async ({ payload }) => {
    const { oeId, viewname } = payload?.parameters || {};

    if (!oeId || viewname !== "initiatives") {
      return;
    }
    const emptyInitiative = getEmptyInitiative();
    const [currentOE, currentOEInitiatives] = await Promise.all([
      fetchOE(oeId),
      fetchOEInitiatives(oeId),
    ]);

    emptyInitiative.operatingEntity = {
      ...currentOE,
    } as OperatingEntity;

    app.patchAppState({
      currentOE,
      currentOEInitiatives,
      currentEditInitiative: emptyInitiative,
      editFormConfirmationDialogType: "cancel-new",
    });

    pimoForm.getFormMethods()?.reset(emptyInitiative);
  });

  (route as Route<"oeId" | "viewname" | "initiativeId", InAppState>)?.on(
    "load",
    async ({ payload }) => {
      const { oeId, initiativeId } = payload?.parameters || {};

      if (
        !oeId ||
        !initiativeId ||
        initiativeId === "new" ||
        initiativeId === "comments"
      ) {
        return;
      }
      const [currentOE, currentOEInitiatives] = await Promise.all([
        fetchOE(oeId),
        fetchOEInitiatives(oeId),
      ]);
      const initiative = currentOEInitiatives.initiatives?.find(
        (initiative) => initiative.uid === initiativeId
      );
      const currentEditInitiative =
        convertInitiativeToEditInitiative(initiative);

      pimoForm.getFormMethods()?.reset(currentEditInitiative);

      app.patchAppState({
        currentOE,
        currentOEInitiatives,
        currentEditInitiative,
        editFormConfirmationDialogType: "cancel-editing",
      });
    }
  );

  // Reset error state on tab when navigating
  app.on("router:navigated-to-path", () => clearErrorStateOnTabs());

  function clearErrorStateOnTabs() {
    return [initiativeTab, impactTab, enablerTab].forEach(
      (tab) => (tab.hasErrors = false)
    );
  }

  function buildInitiativeTab(
    parentTab: FormTab<EditInitiativesResponse, typeof CUSTOM_FIELDS>
  ) {
    parentTab.addLayoutElement({
      type: "form-tab-title",
      label: "Initiative",
    });

    parentTab.addLayoutElement({
      type: "subheadline",
      label: "Profile",
    });

    parentTab.addFormField({
      type: "text",
      label: "Initiative Name*",
      name: "initiativeName",
    });

    parentTab.addFormField({
      type: "text",
      label: "Description",
      name: "initiativeDescription",
      infoSlot: (
        <INFormFieldInfoSlot
          text={FORM_FIELD_INFO_TEXT["initiativeDescription"]}
        />
      ),
    });

    parentTab.addLayoutElement({
      type: "subheadline",
      label: "Phase",
    });

    parentTab.addFormField({
      type: "dropdown",
      label: "Stage*",
      name: "initiativePhase",
      items: INITIATIVE_PHASES.map((phase) => ({
        value: phase,
        label: phase,
      })),
      infoSlot: (
        <INFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["initiativePhase"]} />
      ),
    });

    parentTab.addFormField({
      type: "date",
      label: "Start Date",
      name: "dates.startDate",
      uiOptions: {
        fieldSize: { xs: 6 },
      },
    });

    parentTab.addFormField({
      type: "date",
      label: "End Date",
      name: "dates.endDate",
      uiOptions: {
        fieldSize: { xs: 6 },
      },
      infoSlot: <INFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["endDate"]} />,
    });

    parentTab.addLayoutElement({
      type: "subheadline",
      label: "Scope & Ownership",
    });

    parentTab.addFormField({
      type: "checkbox",
      label: "IT Change Required",
      name: "itChangeRequired",
      uiOptions: { fieldSize: { xs: 6 } },
      infoSlot: (
        <INFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["itChangeRequired"]} />
      ),
    });

    parentTab.addFormField({
      type: "text",
      label: "Team Or Department Affected By The Initiative",
      name: "implementingBusinessUnit",
    });

    parentTab.addFormField({
      type: "dropdown",
      multiSelect: true,
      label: "Function*",
      name: "function",
      items: FUNCTION_TYPES.map((type) => ({ label: type, value: type })),
      infoSlot: <INFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["function"]} />,
    });

    parentTab.addFormField({
      type: "text",
      label: "Sub Function",
      name: "subFunction",
      infoSlot: (
        <INFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["subFunction"]} />
      ),
    });

    parentTab.addFormField({
      type: INITIATIVE_LINE_OF_BUSINESS_FIELD,
      name: "lineOfBusiness",
    });

    parentTab.addFormField({
      type: INITIATIVE_LINE_OF_BUSINESS_OTHER_FIELD,
      name: "lineOfBusinessOther",
    });

    parentTab.addLayoutElement({
      type: "form-tab-title",
      label: "Ownership",
    });

    parentTab.addFormField({
      type: "text",
      label: "Initiative Owner",
      name: "initiativeOwner",
      infoSlot: (
        <INFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["initiativeOwner"]} />
      ),
    });

    parentTab.addFormField({
      type: "dropdown",
      label: "Implementation Program*",
      name: "implementationArm",
      items: IMPLEMENTATION_ARM_TYPES.map((type) => ({
        label: type,
        value: type,
      })),
      infoSlot: (
        <INFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["implementationArm"]} />
      ),
    });

    parentTab.addFormField({
      type: "text",
      label: "Implementation Program Branch",
      name: "implementationArmOther",
      infoSlot: (
        <INFormFieldInfoSlot
          text={FORM_FIELD_INFO_TEXT["implementationArmUnit"]}
        />
      ),
    });

    parentTab.addLayoutElement({
      type: "subheadline",
      label: "Additional Information",
    });

    parentTab.addFormField({
      type: "text",
      label: "KPI Dashboard Link",
      name: "kpiDashboardHyperlink",
      infoSlot: (
        <INFormFieldInfoSlot
          text={FORM_FIELD_INFO_TEXT["kpiDashboardHyperlink"]}
        />
      ),
    });
  }

  function buildImpactTab(
    parentTab: FormTab<EditInitiativesResponse, typeof CUSTOM_FIELDS>
  ) {
    parentTab.addLayoutElement({
      type: "form-tab-title",
      label: "Impact",
      grayBoxText: (
        <INFormFieldInfoSlot
          maxWidth="auto"
          text={FORM_FIELD_INFO_TEXT["impactGrayBox"]}
        />
      ),
    });

    parentTab.addFormField({
      type: "dropdown",
      name: "impactType",
      label: "Impact Type*",
      multiSelect: true,
      items: IMPACT_TYPES.map((type) => ({ label: type, value: type })),
    });

    parentTab.addLayoutElement({
      type: "form-tab-title",
      label: "Qualitative impact",
      isVisibile: (formData: FieldValues) =>
        (formData as EditInitiativesResponse).impactType?.includes(
          "Qualitative"
        ) ?? false,
    });

    parentTab.addFormField({
      type: "dropdown",
      name: "qualitativeImpactType",
      label: "Qualitative impact",
      items: QUALITATIVE_IMPACT_TYPES.map((type) => ({
        label: type,
        value: type,
      })),
      isVisibile: (formData: FieldValues) =>
        (formData as EditInitiativesResponse).impactType?.includes(
          "Qualitative"
        ) ?? false,
    });

    parentTab.addFormField({
      type: "text",
      label: "Qualitative Impact Type Other",
      name: "qualitativeImpactTypeOther",
      isVisibile: (formData: FieldValues) =>
        ((formData as EditInitiativesResponse).impactType?.includes(
          "Qualitative"
        ) &&
          (formData as EditInitiativesResponse).qualitativeImpactType ===
            "Other") ??
        false,
    });

    parentTab.addLayoutElement({
      type: "form-tab-title",
      label: "Quantitative impact",
      isVisibile: (formData: FieldValues) =>
        (formData as EditInitiativesResponse).impactType?.includes(
          "Quantitative"
        ) ?? false,
    });

    parentTab.addFormField({
      type: "dropdown",
      name: "quantitativeImpactType",
      label: "Quantitative impact*",
      multiSelect: true,
      items: QUANTITATIVE_IMPACT_TYPES.map((type) => ({
        label: type,
        value: type,
      })),
      isVisibile: (formData: FieldValues) =>
        (formData as EditInitiativesResponse).impactType?.includes(
          "Quantitative"
        ) ?? false,
    });

    parentTab.addFormField({
      type: INITIATIVE_IMPACT_TABLE_TYPE,
      name: "grossGrowthImpact",
      isVisibile: (formData: FieldValues) => {
        const fd = formData as EditInitiativesResponse;

        return (
          (fd.impactType?.includes("Quantitative") &&
            fd.quantitativeImpactType?.includes("Growth")) ??
          false
        );
      },
    });

    parentTab.addFormField({
      type: INITIATIVE_IMPACT_TABLE_TYPE,
      name: "grossEfficiencyImpact",
      isVisibile: (formData: FieldValues) => {
        const fd = formData as EditInitiativesResponse;

        return (
          (fd.impactType?.includes("Quantitative") &&
            fd.quantitativeImpactType?.includes("Efficiency")) ??
          false
        );
      },
    });

    parentTab.addFormField({
      type: INITIATIVE_IMPACT_TABLE_TYPE,
      name: "grossEffectivenessImpact",
      isVisibile: (formData: FieldValues) => {
        const fd = formData as EditInitiativesResponse;

        return (
          (fd.impactType?.includes("Quantitative") &&
            fd.quantitativeImpactType?.includes("Effectiveness")) ??
          false
        );
      },
    });
  }

  function buildEnablerTab(
    parentTab: FormTab<EditInitiativesResponse, typeof CUSTOM_FIELDS>
  ) {
    parentTab.addLayoutElement({
      type: "form-tab-title",
      label: "Enabler(s)",
    });

    const enablerFieldsAutomationIsVisible = (formData: FieldValues) => {
      return (
        (
          formData as EditInitiativesResponse
        )?.initiativeEnablerFields?.initiativeEnablers?.includes(
          "Automation"
        ) ?? true
      );
    };

    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.initiativeEnablers",
      label: "Initiative Enablers",
      subType: "multiSelect",
    });

    parentTab.addLayoutElement({
      type: "form-tab-title",
      label: "Automation",
      isVisibile: enablerFieldsAutomationIsVisible,
    });

    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.automationAIPowered",
      label: "Automation AI Powered*",
      subType: "checkbox",
      isVisibile: enablerFieldsAutomationIsVisible,
      uiOptions: {
        fieldSize: {
          xs: 6,
        },
      },
    });
    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.automationGenAIPowered",
      label: "Automation Gen AI Powered*",
      subType: "checkbox",
      isVisibile: enablerFieldsAutomationIsVisible,
      uiOptions: {
        fieldSize: {
          xs: 6,
        },
      },
    });

    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.automationComplexity",
      label: "Automation Complexity*",
      subType: "select",
      isVisibile: enablerFieldsAutomationIsVisible,
      uiOptions: {
        fieldSize: {
          xs: 6,
        },
      },
    });

    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.automationInvolvedSystem",
      label: "Automation Involved System*",
      subType: "text",
      isVisibile: enablerFieldsAutomationIsVisible,
      uiOptions: {
        fieldSize: {
          xs: 6,
        },
      },
    });

    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.automationMethodology",
      label: "Automation Methodology*",
      subType: "multiSelect",
      isVisibile: enablerFieldsAutomationIsVisible,
      uiOptions: {
        fieldSize: {
          xs: 6,
        },
      },
    });

    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.automationMethodologyOther",
      label: "Automation Methodology Other*",
      subType: "text",
      isVisibile: (formData: FieldValues) => {
        const initiativeEnablerFields = (formData as EditInitiativesResponse)
          .initiativeEnablerFields;

        return (
          (initiativeEnablerFields?.initiativeEnablers?.includes(
            "Automation"
          ) &&
            initiativeEnablerFields.automationMethodology?.includes("Other")) ??
          false
        );
      },
      uiOptions: {
        fieldSize: {
          xs: 6,
        },
      },
    });

    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.automationSolutionImplementationProvider",
      label: "Automation Solution Implementation Provider*",
      subType: "select",
      isVisibile: enablerFieldsAutomationIsVisible,
      uiOptions: {
        fieldSize: {
          xs: 6,
        },
      },
    });

    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.automationTechnicalSolution",
      label: "Automation Technical Solution*",
      subType: "text",
      isVisibile: enablerFieldsAutomationIsVisible,
      uiOptions: {
        fieldSize: {
          xs: 6,
        },
      },
    });

    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.automationUnderlyingPlatform",
      label: "Automation Underlying Platform*",
      subType: "multiSelect",
      isVisibile: enablerFieldsAutomationIsVisible,
      uiOptions: {
        fieldSize: {
          xs: 6,
        },
      },
    });

    const enablerFieldsProcessMiningIsVisible = (formData: FieldValues) => {
      return (
        (
          formData as EditInitiativesResponse
        ).initiativeEnablerFields?.initiativeEnablers?.includes(
          "Process Mining"
        ) ?? true
      );
    };

    parentTab.addLayoutElement({
      type: "form-tab-title",
      label: "Process Mining",
      isVisibile: enablerFieldsProcessMiningIsVisible,
    });

    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.processMiningEnablementType",
      label: "Process Mining Enablement Type*",
      subType: "multiSelect",
      isVisibile: enablerFieldsProcessMiningIsVisible,
      uiOptions: {
        fieldSize: {
          xs: 6,
        },
      },
    });

    parentTab.addFormField({
      type: INITIATIVE_ENABLER_SUB_FIELD_TYPE,
      name: "initiativeEnablerFields.processMiningEnablementTypeOther",
      label: "Process Mining Enablement Type Other*",
      subType: "text",
      isVisibile: (formData: FieldValues) => {
        const { initiativeEnablerFields } = formData as EditInitiativesResponse;

        return (
          (initiativeEnablerFields?.initiativeEnablers?.includes(
            "Process Mining"
          ) &&
            initiativeEnablerFields.processMiningEnablementType?.includes(
              "Other"
            )) ??
          false
        );
      },
      uiOptions: {
        fieldSize: {
          xs: 6,
        },
      },
    });
  }

  function registerFormEvents() {
    form.on(
      "submit",
      async ({
        payload: payload = {} as EditInitiativesResponse & {
          navigationDirection?: FormNavigationDirection;
        },
      }) => {
        const { navigationDirection } = payload;
        const initiativeToSave = convertEditInitiativeToInitiative(payload);

        if (!initiativeToSave) {
          return;
        }

        const state = app.getAppState();
        const {
          currentOEInitiatives,
          currentOEInitiatives: { initiatives },
        } = state;

        try {
          if (initiativeToSave?.id) {
            const updatedInitiative = await saveInitiative(
              initiativeToSave.id,
              initiativeToSave
            );

            const initiativeIndex = initiatives?.findIndex(
              (initiative) => initiative.id === initiativeToSave.id
            );

            if (
              initiatives &&
              initiativeIndex !== undefined &&
              initiativeIndex !== -1 &&
              updatedInitiative
            ) {
              initiatives[initiativeIndex] = updatedInitiative;
            }
          } else {
            const createdInitiative = await addNewInitiative(initiativeToSave);

            if (createdInitiative) {
              initiatives?.push(createdInitiative);
            }

            app.navigate(
              generatePath(APP_ROUTES.editInitiatives, {
                oeId: String(state.currentOE?.id),
                viewname: "initiatives",
                initiativeId: String(createdInitiative?.uid),
              })
            );
          }

          pimoForm?.getFormMethods()?.reset(payload);
        } catch {
          return;
        }

        app.patchAppState({
          currentEditInitiative: payload,
          currentOEInitiatives: {
            ...currentOEInitiatives,
            initiatives,
          },
        });

        if (!navigationDirection) {
          closeEditForm();
        }
      }
    );

    form.on(
      "submit-without-changes",
      ({
        payload: { navigationDirection } = {} as EditInitiativesResponse & {
          navigationDirection?: FormNavigationDirection;
        },
      }) => {
        if (!navigationDirection) {
          closeEditForm();
        }
      }
    );

    form.on("cancel-with-changes", () => {
      app.patchAppState({
        showEditFormConfirmationDialog: true,
      });
    });

    form.on("cancel", () => closeEditForm());
    form.on("close", () => closeEditForm());

    form.on(
      "navigate-with-changes",
      ({
        payload: { path } = {} as EditInitiativesResponse & {
          path: string;
        },
      }) => {
        const state = app.getAppState();

        app.patchAppState({
          previousEditFormConfirmationDialogType:
            state.editFormConfirmationDialogType,
          editFormConfirmationDialogType: "navigate-with-changes",
          editFormConfirmationNavigatePath: path,
          showEditFormConfirmationDialog: true,
        });
      }
    );
    form.on(
      "navigate",
      ({
        payload: { path } = {} as EditInitiativesResponse & {
          path: string;
        },
      }) => {
        if (path) {
          app.navigate(path);
        }
      }
    );

    form.on("delete", () => {
      app.patchAppState({
        editFormConfirmationDialogType: "delete-confirmation",
        showEditFormConfirmationDialog: true,
      });
    });
  }

  function closeEditForm() {
    const currentOE = app.getAppState().currentOE;

    if (!currentOE) {
      app.navigate(APP_ROUTES.home);
    }

    app.navigate(
      generatePath(APP_ROUTES.operatingEntities, {
        oeId: String(currentOE?.id),
        viewname: "initiatives",
      })
    );
  }

  function onDraftSave() {
    const state = app.getAppState();
    const initiative = state.currentEditInitiative;
    const isDraft = Boolean(initiative?.draft);
    const formMethods = pimoForm.getFormMethods();
    const data = formMethods?.getValues();

    formMethods?.clearErrors();
    clearErrorStateOnTabs();

    // if the initiative is already a draft, we want to publish it
    if (isDraft) {
      // validate the form by using the same form validation as the form itself
      const errors = validateFormFields(data);

      // if there are any errors, populate them and display the info dialog
      if (Object.keys(errors).length) {
        for (const [key, error] of Object.entries(errors)) {
          formMethods?.setError(key as keyof FormFieldErrors, error!);
        }

        void formMethods?.areAllTabsValid();

        app.patchAppState({
          editFormConfirmationDialogType: "publish-error",
          showEditFormConfirmationDialog: true,
        });

        return;
      }
      // if the initiative is not a draft, we want to save it as a draft
    } else {
      // if the initiative name is not filled in, show an error message
      if (!data?.initiativeName) {
        pimoForm.getFormMethods()?.setError("initiativeName", REQUIRED_ERROR);
        initiativeTab.hasErrors = true;

        return;
      }

      saveDraft();
      return;
    }

    app.patchAppState({
      editFormConfirmationDialogType: "publish-confirmation",
      showEditFormConfirmationDialog: true,
    });
  }

  async function deleteInitiative() {
    const state = app.getAppState();
    const { currentOE, currentEditInitiative: initiative } = state;

    if (!initiative?.id) {
      return;
    }

    await markInitiativeDeleted(initiative.id);

    if (currentOE) {
      const currentOEInitiatives = await fetchOEInitiatives(currentOE.id);
      const groupOverview = await fetchOEs(undefined, true);

      app.patchAppState({
        currentOEInitiatives,
        groupOverview,
      });

      app.navigate(
        generatePath(APP_ROUTES.operatingEntities, {
          oeId: currentOE.id,
          viewname: "initiatives",
        })
      );
    }
  }

  function saveDraft() {
    const state = app.getAppState();
    const initiative = state.currentEditInitiative;
    const isDraft = Boolean(initiative?.draft);
    const data = pimoForm.getFormMethods()?.getValues();

    // fire the submit event with the information about the draft status
    form.fireEvent("submit", {
      ...data,
      draft: isDraft ? false : true,
      navigationDirection: "none",
    });
  }

  function createConfirmationDialog() {
    const confirmationDialog = dialog.addComponent({
      component: INFormConfirmationDialog,
    });

    confirmationDialog.mapVisibility(
      ({ showEditFormConfirmationDialog }) =>
        showEditFormConfirmationDialog && showEditFormConfirmationDialog != null
    );

    confirmationDialog.mapState(({ editFormConfirmationDialogType }) => ({
      type: editFormConfirmationDialogType,
    }));

    function closeConfirmationDialog() {
      app.patchAppState({
        showEditFormConfirmationDialog: false,
      });
    }

    confirmationDialog.on("close", () => {
      closeConfirmationDialog();

      const state = app.getAppState();
      const path = state.editFormConfirmationNavigatePath;

      switch (state.editFormConfirmationDialogType) {
        case "navigate-with-changes":
          if (path) {
            app.navigate(path);
          }

          return;

        case "publish-confirmation":
          saveDraft();
          return;

        case "delete-confirmation":
          void deleteInitiative();
          return;

        default:
          break;
      }

      closeEditForm();
    });

    confirmationDialog.on("continue", () => {
      closeConfirmationDialog();

      const state = app.getAppState();

      if (state.editFormConfirmationDialogType === "publish-confirmation") {
        return;
      }

      if (state.previousEditFormConfirmationDialogType) {
        app.patchAppState({
          editFormConfirmationDialogType:
            state.previousEditFormConfirmationDialogType,
        });
      }
    });
  }
}
