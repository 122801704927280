import {
  App,
  composeInitialState,
  LoginPlugin,
  LoginPluginPartialAppState,
  UniversalAppState,
} from "@pimo/pimo-app-builder";
import {
  type DefaultOverlayPartialAppState,
  initialDefaultOverlayState,
  type Log,
} from "@pimo/pimo-components";
import type {
  BffOes,
  BffOverlay,
  FilterData,
  FilterOptions,
  OperatingEntity,
  Profile,
  Program,
} from "in-types";

import { APP_KEY } from "../app-key";
import { APP_ROUTES } from "./constants";
import { STRAPI_URL } from "./env";
import { fetchOEs, fetchProgram } from "./helpers/fetch-helpers.ts";
import { createOverlay } from "./overlay.ts";
import { DashboardPlugin } from "./pages/dashboard-plugin";
import { HomePlugin, HomePluginState } from "./pages/home-plugin";
import {
  OeInitiativesPlugin,
  OEInitiativesPluginPartialState,
} from "./pages/oe-initiatives-plugin.ts";
import { OverviewPlugin } from "./pages/overview-plugin";
import { theme } from "./theme";

export type InAppState = DefaultOverlayPartialAppState &
  LoginPluginPartialAppState<Profile> &
  UniversalAppState &
  HomePluginState &
  OEInitiativesPluginPartialState & {
    groupOverview?: BffOes;
    operatingEntities?: OperatingEntity[];
    overlay?: BffOverlay;
    program?: Program;
    filterDialogData: FilterData;
    filterDialogOptions: FilterOptions;
    logs?: Log[];
  };

// Plugins
const loginPlugin = new LoginPlugin<Profile, InAppState>(
  APP_ROUTES.dashboard,
  STRAPI_URL,
  "Impact Navigator Tool"
);

// App
const app = App.create<InAppState>(
  composeInitialState<InAppState>({
    ...initialDefaultOverlayState,
    ...loginPlugin.getInitialState(),
    isLoading: false,
    isSidebarOpen: true,
    currentEditInitiative: undefined,
    currentOE: undefined,
    showEditFormConfirmationDialog: false,
    editFormConfirmationNavigatePath: undefined,
    editFormConfirmationDialogType: undefined,
    previousEditFormConfirmationDialogType: undefined,
    initiativeSortData: {
      key: "uid",
      direction: "ASC",
    },
    initiativeFilterData: {
      searchQueryFilter: "",
      yearFilter: "",
      phaseFilter: [],
      updateStatusFilter: [],
    },
    currentOEComments: {},
    currentOEInitiatives: {
      aggregatedImpactValues: {
        automationData: [],
        processMiningData: [],
        totalImpactData: [],
        startYear: "",
        startQuarter: "",
      },
      initiatives: [],
      phasesOverview: {},
      totalImpactValue: { value: 0, targetYear: "" },
      updateStatusOverview: {
        completionStatusByEnabler: {},
        updateStatus: {},
        updateProgress: 0,
        numberOfInitiatives: 0,
      },
    },
    filterDialogData: {
      oeNameFilter: [],
      impactLeadFilter: [],
      searchQueryFilter: "",
      updatedFilter: [],
      regionFilter: [],
    },
    filterDialogOptions: {},
    groupOverview: {
      groupImpactValues: {
        automationData: [],
        processMiningData: [],
        totalImpactData: [],
        startYear: "",
        startQuarter: "",
      },
      groupEnablerOverview: {},
      groupPhasesOverview: {},
      groupTotalImpactValue: { value: 0, targetYear: "" },
      groupUpdateStatus: { completed: 0, pending: 0, overdue: 0, total: 0 },
      numberOfInitiatives: 0,
      oeOverview: [],
      oes: [],
    },
    program: { id: 0, reportingDate: "" },
    operatingEntities: [],
  }),
  APP_KEY
);
app.setTheme(theme);

const overlay = createOverlay(app);

overlay.on("overlay:logout", () => {
  loginPlugin.logout();
});

loginPlugin.on("login-status-changed", async (evt) => {
  if (!evt.payload?.isLoggedIn) {
    return;
  }

  const [program, groupOverview] = await Promise.all([
    fetchProgram(),
    fetchOEs(),
  ]);

  app.patchAppState({
    operatingEntities: groupOverview.oes,
    program,
    isLoading: false,
  });
});

const homePlugin = new HomePlugin<InAppState>();
const dashboardPlugin = new DashboardPlugin();
const overviewPlugin = new OverviewPlugin();
const oeInitiativesPlugin = new OeInitiativesPlugin();

app.registerPlugin(loginPlugin);
app.registerPlugin(homePlugin);
app.registerPlugin(oeInitiativesPlugin);
app.registerPlugin(dashboardPlugin);
app.registerPlugin(overviewPlugin);

const RenderedApp = app.render();

export default RenderedApp;
