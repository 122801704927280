import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

export type INFormConfirmationDialogType =
  | "cancel-new"
  | "cancel-editing"
  | "navigate-with-changes"
  | "publish-error"
  | "publish-confirmation"
  | "delete-confirmation";
export type INFormConfirmationDialogProps = {
  type?: INFormConfirmationDialogType;
};
export type INFormConfirmationDialogEvent = "close" | "continue";

const FORM_CONFIRMATION_DEFAULT_ACTIONS = {
  close: {
    text: "Close",
    variant: "contained",
  },
  continue: {
    text: "Continue Editing",
    variant: "text",
  },
};

const FORM_CONFIRMATION_DIALOG_TEXTS = {
  "cancel-new": {
    title: "Close without saving?",
    content:
      "Closing the dialog without saving will cause all data to be lost.",
    actions: FORM_CONFIRMATION_DEFAULT_ACTIONS,
  },
  "cancel-editing": {
    title: "Close without saving?",
    content:
      "Closing the dialog without saving will cause all unsaved data to be lost.",
    actions: FORM_CONFIRMATION_DEFAULT_ACTIONS,
  },
  "publish-error": {
    title: "Validation Error",
    content:
      "Please ensure all required fields are filled in and valid before publishing.",
    actions: {
      continue: {
        ...FORM_CONFIRMATION_DEFAULT_ACTIONS.continue,
        variant: "contained",
      },
    },
  },
  "publish-confirmation": {
    title: "Publish?",
    content: "Publishing the initiative cannot be reversed.",
    actions: {
      ...FORM_CONFIRMATION_DEFAULT_ACTIONS,
      close: {
        text: "Publish",
        variant: "contained",
      },
      continue: {
        ...FORM_CONFIRMATION_DEFAULT_ACTIONS.continue,
        text: "Abort",
      },
    },
  },
  "navigate-with-changes": {
    title: "Continue without saving?",
    content:
      "Navigating to a different initiative without saving will cause all unsaved data to be lost.",
    actions: {
      ...FORM_CONFIRMATION_DEFAULT_ACTIONS,
      close: {
        ...FORM_CONFIRMATION_DEFAULT_ACTIONS.close,
        text: "Continue",
      },
    },
  },
  "delete-confirmation": {
    title: "Delete?",
    content:
      "Are you sure you want to delete this initiative? This can not be undone!",
    actions: {
      continue: {
        ...FORM_CONFIRMATION_DEFAULT_ACTIONS.continue,
        text: "Abort",
      },
      close: {
        ...FORM_CONFIRMATION_DEFAULT_ACTIONS.close,
        text: "Delete",
      },
    },
  },
} as Record<
  INFormConfirmationDialogType,
  {
    title: string;
    content: string;
    actions?: Partial<typeof FORM_CONFIRMATION_DEFAULT_ACTIONS>;
  }
>;

export const INFormConfirmationDialog: PimoReactComponent<
  INFormConfirmationDialogProps,
  INFormConfirmationDialogEvent
> = ({ type, fireEvent }) => {
  const onClose = () => {
    fireEvent?.("close");
  };
  const onContinue = () => {
    fireEvent?.("continue");
  };

  if (!type) {
    return null;
  }

  const texts = FORM_CONFIRMATION_DIALOG_TEXTS[type];
  const { actions, content, title } = texts;

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{ margin: 8 }}
      PaperProps={{ sx: { borderRadius: 5 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>{content}</DialogContent>
      <Divider />
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          m: 2,
          gap: 2,
        }}
      >
        {actions?.continue && (
          <Button
            type="button"
            variant={actions.continue.variant as ButtonProps["variant"]}
            onClick={onContinue}
          >
            {actions.continue.text}
          </Button>
        )}
        {actions?.close && (
          <Button
            type="button"
            variant={actions.close.variant as ButtonProps["variant"]}
            onClick={onClose}
          >
            {actions.close.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
