import { QUARTERS } from "in-utils";

export const generateQuarters = (
  startQuarter: string,
  startYear: string,
  numQuarters: number
): string[] => {
  const quarters: string[] = [];
  let currentQuarterIndex = QUARTERS.indexOf(startQuarter);
  let currentYear = parseInt(startYear, 10);

  for (let i = 0; i < numQuarters; i++) {
    quarters.push(`${QUARTERS[currentQuarterIndex]} ${currentYear}`);
    currentQuarterIndex++;
    if (currentQuarterIndex === 4) {
      currentQuarterIndex = 0;
      currentYear++;
    }
  }
  return quarters;
};

export const aggregateDataYearly = (
  data: (number | null)[],
  initialStartQuarter: string
) => {
  if (!data || data.length === 0) return [];

  const startQuarterIndex = QUARTERS.indexOf(initialStartQuarter);
  let currentQuarter = startQuarterIndex;
  let yearSum = 0;
  const yearlyData = [];

  data.forEach((value, i) => {
    if (currentQuarter === 0 && i !== 0) {
      yearlyData.push(yearSum > 0 ? yearSum : null);
      yearSum = 0;
    }
    yearSum += value ?? 0;
    currentQuarter = (currentQuarter + 1) % QUARTERS.length;
  });

  yearlyData.push(yearSum > 0 ? yearSum : null);

  return yearlyData;
};
