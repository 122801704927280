import { App, Route } from "@pimo/pimo-app-builder";
import {
  CustomFormFields,
  PimoFormDialog,
  PimoFormEvent,
} from "@pimo/pimo-components";
import { ImpactPlanByYear } from "in-types";
import { generatePath } from "react-router-dom";
import { InAppState } from "../app/app";
import { APP_ROUTES } from "../app/constants";
import { saveImpactPlansForOE } from "../app/helpers/fetch-helpers";
import {
  IMPACT_PLAN_FIELD_TYPE,
  ImpactPlanField,
} from "../components/form-fields/impact-plan";

const CUSTOM_FIELDS = {
  [IMPACT_PLAN_FIELD_TYPE]: ImpactPlanField,
} as const satisfies CustomFormFields<typeof IMPACT_PLAN_FIELD_TYPE>;

export function buildImpactPlanFormRoute(
  app: App<InAppState>,
  parentRoute: Route<"oeId" | "viewname", InAppState>
) {
  const dialog = app.createView<"oeId" | "viewname">({
    name: "Edit-Impact-Plan",
  });
  const pimoForm = new PimoFormDialog<
    {
      impactPlans: ImpactPlanByYear;
    },
    typeof CUSTOM_FIELDS
  >();

  pimoForm.registerFormField(IMPACT_PLAN_FIELD_TYPE, ImpactPlanField);

  const form = dialog.addComponent({ component: pimoForm });

  form.mapState(({ currentOE }) => {
    return {
      actions: {
        "save-and-back": { visible: false },
      },
      impactPlans: currentOE?.impactPlans ?? {},
      runValidationOn: "blur",
    };
  });

  form.on("submit", async ({ payload }) => {
    const state = app.getAppState();

    if (!state.currentOE) {
      return;
    }

    const oe = await saveImpactPlansForOE({
      ...state.currentOE,
      impactPlans: {
        ...state.currentOE?.impactPlans,
        ...(payload?.impactPlans ?? {}),
      },
    });

    app.patchAppState({
      currentOE: oe,
    });

    closeForm();
  });

  (
    [
      "cancel",
      "cancel-with-changes",
      "submit-without-changes",
    ] satisfies PimoFormEvent[]
  ).forEach((eventName) => {
    form.on(eventName, () => {
      closeForm();
    });
  });

  parentRoute.createChildRoute({
    path: APP_ROUTES.editImpactPlan,
    view: dialog,
    isOverlayChild: true,
  });

  const tab = pimoForm.addFormTab({
    label: "Productivity Impact Plan",
  });

  tab.addFormField({
    type: IMPACT_PLAN_FIELD_TYPE,
    name: "impactPlans",
  });

  function closeForm() {
    const currentOE = app.getAppState().currentOE;

    if (!currentOE) {
      app.navigate(APP_ROUTES.home);
    }

    app.navigate(
      generatePath(APP_ROUTES.operatingEntities, {
        oeId: String(currentOE?.id),
        viewname: "impact-plan",
      })
    );
  }
}
