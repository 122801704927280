import { Box, Button, useTheme } from "@mui/material";
import {
  ContentBox,
  PimoTableEventNames,
  PimoTableEventPayload,
} from "@pimo/pimo-components";
import { InitiativeFilterData } from "in-types";
import { IN_COLORS } from "in-utils";
import React, { PropsWithChildren } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InitiativesStatusTableFilter } from "./initiatives-status-table-filter";

import { STRAPI_URL } from "../../app/env";

export type StatusReportTableContainerProps = PropsWithChildren<{
  title: string;
  addInitiativePath: string;
  editInitiativePath: string;
  filterData: InitiativeFilterData;
  fireEvent?: (
    eventName: PimoTableEventNames,
    payload: PimoTableEventPayload
  ) => void;
}>;

export const InitiativesStatusTableContainer = React.forwardRef(
  (
    {
      children,
      title,
      editInitiativePath,
      addInitiativePath,
      filterData,
      fireEvent,
    }: StatusReportTableContainerProps,
    ref
  ) => {
    const navigate = useNavigate();
    const params = useParams<"oeId">();
    const theme = useTheme();

    return (
      <ContentBox
        ref={ref}
        icon={
          <Box
            component="img"
            src="checklist.svg"
            sx={{
              backgroundColor: "white",
              borderRadius: "12px",
              p: "5px",
              height: "36px",
            }}
          />
        }
        title={title}
        statusBar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              disabled={false}
              sx={{
                border: "1px solid",
                padding: "4px 16px",
                color: IN_COLORS.white,
                borderCollapse: theme.palette.primary.dark,
                borderRadius: "4px",
              }}
              onClick={() =>
                window.open(
                  [STRAPI_URL, "api/export", params.oeId]
                    .filter(Boolean)
                    .join("/")
                )
              }
            >
              Download Data
            </Button>
            <Button
              disabled={false}
              sx={{
                borderRadius: "4px",
                background: IN_COLORS.white,
                marginLeft: "12px",
                "&:hover": {
                  background: IN_COLORS.white,
                },
              }}
              variant="outlined"
              type="button"
              onClick={() => navigate(addInitiativePath)}
            >
              Add Initiative
            </Button>
            <Button
              disabled={false}
              sx={{
                borderRadius: "4px",
                background: IN_COLORS.white,
                marginLeft: "12px",
                "&:hover": {
                  background: IN_COLORS.white,
                },
              }}
              variant="outlined"
              type="button"
              onClick={() => navigate(editInitiativePath)}
            >
              Edit
            </Button>
            <InitiativesStatusTableFilter
              fireEvent={fireEvent}
              filterData={filterData}
            />
          </Box>
        }
      >
        <Box sx={{ overflowY: "auto", width: "100%" }}>{children}</Box>
      </ContentBox>
    );
  }
);
